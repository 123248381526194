<template>
	<div>
		<h4>Durante a visita toma atenção às recomendações abaixo:</h4>
		<p>• Se vires algum sku sem etiqueta de preço – alerta um colaborador da loja!</p>
		<p>• Se vires algum sku em rutura (existe etiqueta de preço mas não existe produto) – solicita a reposição junto de um colaborador da loja!</p>
		<p>• Se vires algum sku com questões de qualidade (deformação, condensação, sujidade, bolor) – tira fotografias ao produto e ao rótulo e envia para a Ana Gonçalves, com referência ao nome da loja.</p>
	</div>
</template>

<script>
export default {
	name: "PerfectStoreTips",
}
</script>

<style scoped>

</style>
