import ziggyRoute from "ziggy-js";
import { Ziggy as ziggyConfig } from "./ziggy";
import routes from "./base-routes.js";

window.route = function () {
	let args = Array.prototype.slice.call(arguments);
	let name = args.shift();

	if (routes[name] === undefined) {
		console.error("Unknown route:", name, "please exec npm run dev or check in files route.php");
	} else {
		return (
			"/" +
			routes[name]
				.split("/")
				.map(s => (s[0] === "{" ? args.shift() : s))
				.join("/")
		);
	}
};

ziggyConfig.url = window.location.origin;

window.router = (name, params, absolute, config = ziggyConfig) => ziggyRoute(name, params, absolute, config);
window.$route = window.router;
