import Vue from 'vue';
import Multiselect from 'vue-multiselect'
import VueSignaturePad from 'vue-signature-pad';

Vue.use(VueSignaturePad);
Vue.component('SelectShopGlobal', (resolve) => require(['./components/SelectShop'], resolve))
// register globally
Vue.component('Multiselect', Multiselect);

import { Money } from 'v-money';
Vue.component('VMoney', Money);

import VueMask from 'v-mask'
Vue.use(VueMask);

//Estou importando desta forma devido ao sourcemap que fica dando erro
import StarRating from '../../node_modules/vue-star-rating/src/index.js';
Vue.component('StarRating', StarRating);

//Components passport
Vue.component(
    'PassportClients',
    require('./components/passport/Clients.vue')
);

Vue.component(
    'PassportAuthorizedClients',
    require('./components/passport/AuthorizedClients.vue')
);

Vue.component(
    'PassportPersonalAccessTokens',
    require('./components/passport/PersonalAccessTokens.vue')
);

Vue.component('ButtonToggleText', function(resolve){
    require(['./components/Common/ButtonToggleText.vue'], resolve);
});

Vue.component('GroupedErrors', function(resolve){
    require(['./components/Common/GroupedErrors.vue'], resolve);
});

Vue.component('NotesVue', function(resolve){
  	require(['./components/Common/Notes.vue'], resolve);
});

Vue.component('Addresses', function(resolve){
	require(['./components/Common/Addresses.vue'], resolve);
});

Vue.component('CalendarVisits', function(resolve){
    require(['./Visits/CalendarVisits.vue'], resolve)
});

Vue.component('ModalVisit', function(resolve) {
	require(['./Visits/ModalVisit.vue'], resolve)
})

Vue.component('Modal', function (resolve) {
    require(['./components/Common/Modal'], resolve)
});

Vue.component('ModalContactShop', function (resolve) {
    require(['./components/ModalContactShop.vue'], resolve)
});

Vue.component('ContactModal', function (resolve) {
	require(['./components/Common/ContactModal.vue'], resolve)
});



Vue.component('TableVue', function (resolve) {
    require(['./components/Common/Table/Table'], resolve)
});

Vue.component('CompetitorReport', function (resolve) {
	require(['./components/Reports/CompetitorReport'], resolve)
});

Vue.component('PermanentImplementationReport', function (resolve) {
	require(['./components/Reports/PermanentImplementationReport'], resolve)
});

Vue.component('SaleReport', function (resolve) {
	require(['./components/Reports/SaleReport'], resolve)
});

Vue.component('ShareOfShelfReport', function (resolve) {
	require(['./components/Reports/ShareOfShelfReport'], resolve)
});

Vue.component('StockBreakReport', function (resolve) {
	require(['./components/Reports/StockBreakReport'], resolve)
});

Vue.component('CampaignReport', function (resolve) {
	require(['./components/Reports/CampaignReport'], resolve)
});

Vue.component('Fotorama', function (resolve) {
	require(['./components/fotorama/Fotorama'], resolve)
});

Vue.component('Galery', function (resolve) {
	require(['./components/fotorama/Galery'], resolve)
});

Vue.component('FormSelectorInput', function (resolve) {
	require(['./components/FormFields/Selector'], resolve)
});

Vue.component('FormMultipleSelectorInput', function (resolve) {
	require(['./components/FormFields/MultipleSelector'], resolve)
});

Vue.component('FormRadioInput', function (resolve) {
	require(['./components/FormFields/Radio'], resolve)
});

Vue.component('FormNumberInput', function (resolve) {
	require(['./components/FormFields/Number'], resolve)
});

Vue.component('FormTextInput', function (resolve) {
	require(['./components/FormFields/Text'], resolve)
});

Vue.component('FormDateInput', function (resolve) {
	require(['./components/FormFields/Date'], resolve)
});

Vue.component('FormTextareaInput', function (resolve) {
	require(['./components/FormFields/Textarea'], resolve)
});

Vue.component('FormMoneyInput', function (resolve) {
	require(['./components/FormFields/Money'], resolve)
});

Vue.component('BackToTop', function (resolve) {
	require(['./components/Common/BackToTop'], resolve)
});

Vue.component('ReportFiltersBtn', function (resolve) {
	require(['./components/Common/ReportFiltersBtn'], resolve)
});

Vue.component('VueCounterUp', function(resolve) {
	require(['./components/Common/CounterUp.vue'], resolve)
})

Vue.component('UserDetails', function(resolve) {
	require(['./components/Common/UserDetails.vue'], resolve)
})

Vue.component('FormProductMultiple', function (resolve) {
	require(['./components/FormFields/ProductMultiple'], resolve)
});

Vue.component('FormProductSingle', function (resolve) {
	require(['./components/FormFields/ProductSingle'], resolve)
});

Vue.component('FormSelectorCompetitors', function (resolve) {
	require(['./components/FormFields/SelectorCompetitors.vue'], resolve)
});

Vue.component('FormSelectorBel', function (resolve) {
	require(['./components/FormFields/SelectorBel.vue'], resolve)
});

Vue.component('FormDatalist', function (resolve) {
	require(['./components/FormFields/Datalist.vue'], resolve)
});

Vue.component('FormDateRange', function (resolve) {
	require(['./components/FormFields/DateRange.vue'], resolve)
});

Vue.component('EventVisitShow', function(resolve) {
	require(['./Visits/EventShow.vue'], resolve)
})

Vue.component('AnomalyGraphs', function(resolve) {
	require(['./components/graphs/anomaly.vue'], resolve)
})

Vue.component('LinearGraphs', function(resolve) {
	require(['./components/graphs/linear.vue'], resolve)
})

Vue.component('FormSelectorChain', function(resolve) {
	require(['./components/FormFields/SelectorChain.vue'], resolve)
})

Vue.component('FormCheckboxInput', function(resolve) {
	require(['./components/FormFields/Checkbox'], resolve)
});

import PerfectStoreTips from "@/components/Reports/PerfectStoreTips.vue";
Vue.component('PerfectStoreTips', PerfectStoreTips)

Vue.component('FormSignaturePad', function(resolve) {
	require(['./components/FormFields/SignaturePad'], resolve)
});
