import Vue from "vue";
import axios from './services/axios'
// window.axios = require('axios');
window.axios = axios
window.Vue = Vue;

window.momentJS = require('moment');

window.blobUtil  = require('blob-util');

require('./sweetAlertCustom.js');

require('./routes/routes.js');

require('./vue.js');

require('./helpers.js');

Vue.prototype.$utils = HelperJS

//require('./notifications/laravel-echo');
