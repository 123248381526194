import axios from 'axios';

axios.interceptors.request.use(config => {
	return config;
});

axios.interceptors.response.use(response => {
	return response;
});

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

export default axios;
