export default {
	data(){
		return {
			current_sort: 'name',
			current_sort_dir: 'asc',
			data: [],
			// columns: []
		}
	},
	computed: {
		filterRows(){
			let values = this.data;
			try {
				return values.sort((a, b) => {
					let modifier = 1;

					//Pego as keys indicadas, caso vier (answer.type)
					const [key, sortType] = this.current_sort.split('.');

					let compare_a = a[key]
					let compare_b = b[key]

					if(sortType) {
						compare_a = a[key][sortType]
						compare_b = b[key][sortType]
					}

					if(this.current_sort_dir === 'desc') {
						modifier = -1;
					}

					if(compare_a < compare_b) {
						return -1 * modifier;
					}

					if(compare_a > compare_b) {
						return modifier << 0;
					}

					return 0;
				});
			} catch (e) {
				console.error(e)
				return values
			}
		},
		columnsComputed() {
			if(this.columns.length > 0){
				// console.debug("Columns props", this.columns);
				return this.columns;
			}

			if (this.data.length === 0) {
				// console.debug("without rows");
				return [];
			}

			return Object.keys(this.data[0]).map((key) => {
				return {name: key, data: key}
			});
		},
	},
	methods: {
		sort(sort) {
			//if s == current sort, reverse
			if(sort === this.current_sort) {
				this.current_sort_dir = this.current_sort_dir === 'asc' ? 'desc' : 'asc';
			}
			this.current_sort = sort;
		},
	}
}
