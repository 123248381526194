var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("h4", [
        _vm._v("Durante a visita toma atenção às recomendações abaixo:")
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "• Se vires algum sku sem etiqueta de preço – alerta um colaborador da loja!"
        )
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "• Se vires algum sku em rutura (existe etiqueta de preço mas não existe produto) – solicita a reposição junto de um colaborador da loja!"
        )
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "• Se vires algum sku com questões de qualidade (deformação, condensação, sujidade, bolor) – tira fotografias ao produto e ao rótulo e envia para a Ana Gonçalves, com referência ao nome da loja."
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }