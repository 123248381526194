const selectorDatatableDefault = "#global-datatable";
const selectorInputSearchDefault = "#searchInputTable";
const delayDefault = 500;
import {
	isObject,
	isArray,
	filterObject,
	isUndefinedOrNull,
	isEmptyString,
	deepCopy,
	validateEmail,
} from "tupy-helperjs";
import { debounce, toArray, flatten } from "lodash";

export const toCurrency = (value = 0) =>
	value ? value.toLocaleString("pt-BR", { style: "currency", currency: "EUR" }) : "€ 0";

export const getMemoryUsage = () => {
	const memory = performance.memory;
	if (memory && memory.usedJSHeapSize) {
		return (memory.usedJSHeapSize / (1024 * 1024)).toFixed(2);
	}
	return 'N/A';
}

window.HelperJS = {
	isObject,
	isArray,
	toCurrency,
	filterObject,
	debounce,
	isUndefinedOrNull,
	isEmptyString,
	deepCopy,
	validateEmail,
	getMemoryUsage,
	/**
	 *
	 * @param config {DataTables.Settings}
	 * @returns {DataTables.Settings}
	 */
	commonConfigDatatable: (config = {}) => {
		return Object.assign({}, {
			processing: true,
			serverSide: true,
			searchDelay: 1000,
			responsive: true,
			pageLength: 10,
			colReorder: true,
			paging: true,
			info: true,
			language: {
				sProcessing: "<i class='fa fa-spinner fa-spin fa-2x fa-fw'></i><br>Processing",
				sLengthMenu: "Registos por página _MENU_",
				sZeroRecords: "Sem Resultados",
				// sInfo: "<span class='datatables_counter_resume'>A mostrar de _START_ a _END_ | Total:  _TOTAL_ registos </span>",
				sInfo: "Total:  _TOTAL_ registos",
				sInfoEmpty: "<span  class='datatables_counter_resume'></span>0 registos",
				sInfoFiltered: "<span class='datatables_counter_resume'>(filtrado de _MAX_ total)</span>",
				sSearch: "<span class='font-normal'> <i class='fa fa-search lightblue'></i></span>",
				searchPlaceholder: "Search",
				sUrl: "",
				oPaginate: {
					sFirst: "Primeiro",
					sPrevious: "<",
					sNext: ">",
					sLast: "Último",
				},
				paginate: {
					previous: "<i class='mdi mdi-chevron-left'>",
					next: "<i class='mdi mdi-chevron-right'>",
				},
			},
			stateSave: false,
			drawCallback: function (oSettings) {
				const total = oSettings.fnRecordsDisplay();
				$('#hammer-datatable-total-records').html(`Total de registos: ${total}`);
				if (oSettings._iDisplayLength > oSettings.fnRecordsDisplay()) {
					$(oSettings.nTableWrapper).find('.dataTables_paginate').hide();
				} else {
					$(oSettings.nTableWrapper).find('.dataTables_paginate').show();
				}

				$('.dataTables_paginate > .pagination').addClass('pagination-rounded');

				const results = $(".dataTables_wrapper > div.row:nth-child(3) > div:first-child");

				$(".dataTables_wrapper > div.row:nth-child(1) > div").removeClass("col-sm-12").addClass("col-12").removeClass("col-md-6").addClass("col-sm-6");

				$(".dataTables_wrapper > div.row:nth-child(1) > div:last-child").html(results.html()).show();

				results.hide();
			},
		}, config);
	},

	delayQuery: function (callback, ms) {
		let timer = 0;
		return function () {
			let context = this,
				args = arguments;
			clearTimeout(timer);
			timer = setTimeout(function () {
				callback.apply(context, args);
			}, ms || 0);
		};
	},

	baseConfigDatatable() {
		return {
			processing: true,
			serverSide: true,
			searchDelay: 1000,
			responsive: true,
			pageLength: 10,
			colReorder: true,
			paging: true,
			info: true,
			language: {
				sProcessing: "<i class='fa fa-spinner fa-spin fa-2x fa-fw'></i><br>Processing",
				sLengthMenu: " _MENU_ registos",
				sZeroRecords: "Sem Resultados",
				sInfo: "<span class='datatables_counter_resume'>A mostrar de _START_ a _END_ | Total:  _TOTAL_ registos </span>",
				sInfoEmpty: "<span  class='datatables_counter_resume'></span>0 registos",
				sInfoFiltered: "<span class='datatables_counter_resume'>(filtrado de _MAX_ total)</span>",
				//"sInfoPostFix: "",
				sSearch: "<span class='font-normal'> <i class='fa fa-search lightblue'></i></span>",
				searchPlaceholder: "Search",
				sUrl: "",
				oPaginate: {
					sFirst: "Primeiro",
					sPrevious: "<",
					sNext: ">",
					sLast: "Último",
				},
			},
		};
	},

	searchInputTable: function (
		selectorDatatable = selectorDatatableDefault,
		selectorInputSearch = selectorInputSearchDefault,
		delay = 500
	) {
		$(selectorInputSearch).keyup(
			HelperJS.delayQuery(function () {
				// if( this.value.length < 3 ) return;
				$(selectorDatatable).DataTable().search(this.value).draw();
			}, delay)
		);
	},

	commonFunctionDatatable: function (
		selectorDatatable = selectorDatatableDefault,
		selectorInputSearch = selectorInputSearchDefault,
		delay = delayDefault
	) {
		setTimeout(() => {
			$(".dataTables_filter").remove();
		}, 10);

		HelperJS.searchInputTable(selectorDatatable, selectorInputSearch, delay);

		$.fn.dataTable.ext.errMode = "throw";
		$(selectorDatatable).on("error.dt", function (e, settings, techNote, message) {
			console.error(message);
			//function javascript no SeetAlert
			alertErrorDatatable();
		});
	},

	getLang: function () {
		if (navigator.languages !== undefined) {
			return navigator.languages[0];
		} else {
			return navigator.language;
		}
	},
	processAlert: function (attributes) {
		if (typeof attributes === "object") {
			let message = attributes.message;
			let timer = attributes.timer;
			let type = attributes.type;
			let position = attributes.position;
			let typeAlert = attributes.typeAlert;
			switch (typeAlert) {
				case "swall":
					swallCustom(message, type, position, timer);
					break;

				case "toast":
					toastMessage(message, type, position, timer);
					break;

				default:
			}
		}
	},

	/**
	 * Faço o retorno de uma promise para que possa utilizar uma função assíncrono no Vue
	 * Assim torna compatível com o retorno da chamada axios
	 * @returns {Promise<unknown>|Promise<[]>} Array shops
	 * @param user_id
	 */
	getShopsLocalStorageOrRequest(user_id = null) {
		if (user_id === null) {
			let user = HelperJS.getUserStorage();
			user_id = user.id;
		}

		let keyStorageShop = `my_shops_${user_id}`;
		if (localStorage.getItem(keyStorageShop)) {
			try {
				let shops = JSON.parse(localStorage.getItem(keyStorageShop));
				return Promise.resolve(shops);
			} catch (e) {
				console.error(e);
				return Promise.reject(new Error("Erro ao buscar as lojas em cache"));
			}
		} else {
			return axios
				.get(route("api.shop.search"))
				.then(({ data }) => {
					let newShops = data.map((shop, key) => {
						return {
							id: shop.id,
							name: HelperJS.decodeString(shop.name),
						};
					});
					// localStorage.setItem(keyStorageShop, JSON.stringify(newShops));
					console.debug("Made request, now in cache", newShops);
					// console.table(newShops);
					return newShops;
				})
				.catch(error => {
					console.log(error);
					toastError();
				});
		}
	},

	getChainsLocalStorageOrRequest(user_id = null) {
		if (user_id === null) {
			let user = HelperJS.getUserStorage();
			user_id = user.id;
		}

		let keyStorageChain = `my_chains_${user_id}`;
		if (localStorage.getItem(keyStorageChain)) {
			return new Promise((resolve, reject) => {
				let chains;
				chains = JSON.parse(localStorage.getItem(keyStorageChain));
				resolve(chains);
				reject(new Error("Erro ao buscar os banners em cache"));
				console.info("Chains in cache");
				console.table(chains);
			});
		} else {
			return axios
				.get(route("api.chain.search"))
				.then(({ data }) => {
					let chainsRequest = data.data;
					let newChains = [];
					chainsRequest.forEach((chain, key) => {
						newChains.push({
							id: chain.id,
							name: HelperJS.decodeString(chain.name),
						});
					});
					localStorage.setItem(keyStorageChain, JSON.stringify(newChains));
					console.info("Made request chains, now in cache");
					console.table(newChains);
					return newChains;
				})
				.catch(error => {
					console.log(error);
					toastError();
				});
		}
	},

	lowerBound(num, limit) {
		return num >= limit ? num : limit;
	},

	/**
	 *
	 * @param searchParam {string} O valor que deseja atualizar ou adicionar no URL
	 * @param searchValue {string} O valor a adicionar
	 * @param redirect {Boolean} Redirect ou apenas o novo url
	 * @returns {string} | redirect
	 */
	searchQueryString(searchParam, searchValue, redirect = true) {
		let url = new URL(document.URL);

		let query_string = url.search;

		let search_params = new URLSearchParams(query_string);

		//Set new param in url
		search_params.set(searchParam, searchValue);

		// change the search property of the main url
		url.search = search_params.toString();

		if (redirect) {
			// the new url string
			window.location = url.toString();
		} else {
			return url.toString();
		}
	},

	/**
	 *
	 * @returns array | null
	 */
	getSearchParametersUrl() {
		let params = window.location.search.substr(1);
		return params != null && params !== "" ? HelperJS.transformToAssocArray(params) : {};
	},

	/**
	 *
	 * @param paramsString {string}
	 * @returns array
	 */
	transformToAssocArray(paramsString) {
		let newParams = {};
		let params = paramsString.split("&");
		for (let i = 0; i < params.length; i++) {
			let tempParams = params[i].split("=");
			newParams[tempParams[0]] = tempParams[1];
		}
		return newParams;
	},

	/**
	 *
	 * @param input
	 * @returns {string|*}
	 */
	decodeString(input) {
		if (/&amp;|&quot;|&#39;|'&lt;|&gt;/.test(input)) {
			let doc = new DOMParser().parseFromString(input, "text/html");
			return doc.documentElement.textContent;
		}
		return input;
	},

	getUserStorage() {
		return JSON.parse(localStorage.getItem("user"));
	},

	test: function () {
		return {
			test: function () {
				console.log("aqui");
			},
		};
	},

	/**
	 * @param {Moment} startDate
	 * @param {Moment} endDate
	 * @returns {number}
	 */
	countWeekDays(startDate, endDate) {
		let start = startDate;
		let weekdayCounter = 0;

		while (start <= endDate) {
			if (start.format("ddd") !== "Sat" && start.format("ddd") !== "Sun") {
				weekdayCounter++; //add 1 to your counter if its not a weekend day
			}
			start = moment(start, "YYYY-MM-DD").add(1, "days"); //increment by one day
		}

		return weekdayCounter;
	},

	/**
	 * @link https://www.qodo.co.uk/blog/javascript-restrict-keyboard-character-input/
	 * @param myField
	 * @param e
	 * @param restrictionType [digitsOnly, floatOnly, alphaOnly]
	 */
	restrictCharacters(myField, e, restrictionType) {
		switch (restrictionType) {
			case "digitsOnly":
				restrictionType = /[1234567890]/g;
				break;
			case "floatOnly":
				restrictionType = /[0-9\.]/g;
				break;
			case "alphaOnly":
				restrictionType = /[A-Za-z]/g;
				break;

			default:
				return false;
		}

		if (!e) e = window.event;
		let code;
		if (e.keyCode) code = e.keyCode;
		else if (e.which) code = e.which;
		let character = String.fromCharCode(code);
		// if they pressed esc... remove focus from field...
		if (code === 27) {
			this.blur();
			return false;
		}
		// ignore if they are press other keys
		// strange because code: 39 is the down key AND ' key...
		// and DEL also equals .
		if (
			!e.ctrlKey &&
			code !== 9 &&
			code !== 8 &&
			code !== 36 &&
			code !== 37 &&
			code !== 38 &&
			(code !== 39 || (code === 39 && character === "'")) &&
			code !== 40 &&
			//Marretei pra cá esses codes... pois são do teclado numérico
			!((code >= 96 && code <= 105) || code === 110)
		) {
			return !!character.match(restrictionType);
		}
	},

	isMobile() {
		let check = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS|Windows Phone/i;
		return check.test(navigator.userAgent);
	},

	isDesktop() {
		return !HelperJS.isMobile();
	},

	toSnakeCase(str) {
		str = String(str);
		return (
			str &&
			str
				.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
				.map(strLower => strLower.toLowerCase())
				.join("_")
		);
	},

	toTitleCase(str) {
		if (str === null || str === "") {
			return str;
		} else {
			str = str.toString();
		}

		return str.replace(/\w\S*/g, function (txt) {
			return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
		});
	},

	/**
	 *
	 * @param route {String}
	 * @param nameUser {String}
	 * @param redirectOnSuccess {URL}
	 * @param messageSuccess {String}
	 * @param messageConfirm {String}
	 * @param messageCancel {String}
	 * @param messageError {String}
	 * @param confirmButtonText {String}
	 * @param cancelButtonText {String}
	 * @param {String} typeAlert 'warning', 'info'
	 * @param confirmButtonColor
	 * @param cancelButtonColor
	 */
	alertDelete({
		action,
		nameUser = null,
		redirectOnSuccess = null,
		messageSuccess = "Apagado com sucesso!",
		messageConfirm = "Tem certeza que deseja apagar este item?",
		messageCancel = "Ufaaa! Não apagamos nada.",
		messageError = undefined,
		confirmButtonText = "Apagar",
		cancelButtonText = "Cancelar",
		typeAlert = "warning",
		confirmButtonColor = "#f1556c",
		cancelButtonColor = "#4fc6e1",
	}) {
		const swalWithBootstrapButtons = Swal.mixin({
			buttonsStyling: true,
		});

		let htmlReturn = "";

		if (nameUser) {
			console.debug(nameUser);
			htmlReturn = `<b>${nameUser}!</b><br/>`;
		}

		htmlReturn = htmlReturn + messageConfirm;

		return swalWithBootstrapButtons
			.fire({
				html: htmlReturn,
				type: typeAlert,
				showCancelButton: true,
				confirmButtonText: confirmButtonText,
				cancelButtonText: cancelButtonText,
				reverseButtons: true,
				confirmButtonColor: confirmButtonColor,
				cancelButtonColor: cancelButtonColor,
			})
			.then(result => {
				if (result.value) {
					return axios
						.delete(action)
						.then(response => {
							toastSuccess(messageSuccess);

							if (redirectOnSuccess) {
								location.href = redirectOnSuccess;
							}

							return Promise.resolve(response.data);
						})
						.catch(error => {
							let message = "Não conseguimos processar o pedido, tente novamente mais tarde!";
							if (!messageError) {
								if (error.response.data) {
									message = error.response.data;
								}

								if (error.response.data.message) {
									message = error.response.data.message;
								}
							}

							toastError(message);
							console.error("Error delete server", error.response.data);

							return Promise.reject(error);
						});
				} else if (result.dismiss === Swal.DismissReason.cancel) {
					toastInfo(messageCancel);
					return Promise.reject("cancel");
				}
			})
			.catch(error => {
				toastError(messageError);
				console.error("error delete", error);
				return Promise.reject(error);
			});
	},

	generateMonths(limitRange = 12) {
		// let init = momentJS();
		let init = new Date();

		let months = [];

		// let optionDate = { year: 'numeric', month: 'long' };

		for (let i = 0; i < limitRange; i++) {
			months.push({
				name: init.toLocaleDateString("pt-BR", {
					year: "numeric",
					month: "long",
				}),
				value: init.toISOString(),
			});
			init.setMonth(init.getMonth() + 1);
		}
		// console.table(months)
		return months;
	},

	monthViewEvent(data) {
		return new Date(data).toLocaleDateString("pt-BR", {
			year: "numeric",
			month: "long",
		});
	},

	strLimit(str, limit = 50, end = "...") {
		if (str.length > limit) {
			return `${str.substring(0, limit)} ${end}`;
		}

		return str;
	},

	/**
	 * Extract the errors from the response object.
	 *
	 * @param  {Object} response
	 * @return {Object}
	 */
	extractErrors(response) {
		if (typeof response.data === "string") {
			return { error: response.data };
		}

		if (!response.data || typeof response.data !== "object") {
			return { error: "Não foi possível processar o pedido" };
		}

		if (response.data.errors) {
			return { ...response.data.errors };
		}

		if (response.data.message) {
			return { error: response.data.message };
		}

		return { ...response.data };
	},

	handleDownload(url) {
		return axios
			.post(url, {
				responseType: "blob",
			})
			.then(response => {
				let fileName = this.fileNameByAxiosHeader(response);
				let typeDownload = response.headers["content-type"];

				const urlDownload = URL.createObjectURL(
					new Blob([response.data], {
						type: typeDownload,
					})
				);

				const link = document.createElement("a");
				link.href = urlDownload;
				link.setAttribute("download", fileName);
				document.body.appendChild(link);
				link.click();
			})
			.catch(error => {
				console.error(error);
				toastError("Não foi possível efetuar o download");
			});
	},

	randomString(length) {
		const randomChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
		let result = "";
		for (let i = 0; i < length; i++) {
			result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
		}
		return result;
	},

	fileNameByAxiosHeader: function (response) {
		const [part1, contentName] = response.headers["content-disposition"].split("; ");

		const [index, fileNameNew] = contentName.split("=");

		return fileNameNew;
	},

	count(elements) {
		if (isArray(elements)) {
			return elements.length;
		}

		if (isObject(elements)) {
			return Object.keys(elements).length;
		}

		return 0;
	},

	uiAvatar(name = "BEL") {
		return `https://ui-avatars.com/api/?name=${name}&color=ffffff&background=005640&length=3&size=64&font-size=0.33`;
	},

	removeTags(str) {
		if (str === null || str === "") return false;
		else str = str.toString();
		return str.replace(/(<([^>]+)>)/gi, "");
	},

	formatterErrorsLaravel: function (error, messageDefault = "Não foi possível atender o pedido") {
		let formatted;

		if (this.isUndefinedOrNull(error)) {
			return [messageDefault];
		}

		if (this.isArray(error)) {
			return error;
		}

		if (!isObject(error)) {
			console.debug('error not object', error);
			return [messageDefault];
		}

		if (error.hasOwnProperty("response") && error.response.hasOwnProperty("data")) {
			if (isObject(error.response.data.errors)) {
				formatted = flatten(toArray(error.response.data.errors));
			} else if (isObject(error.response.data)) {
				formatted = flatten(toArray(error.response.data));
			} else if (error.response.data.hasOwnProperty("message")) {
				formatted = [error.response.data.message];
			} else {
				formatted = [error.response.data];
			}
		} else if (error.hasOwnProperty("responseJSON")) {
			let errorJson = error.responseJSON;
			console.debug("errorJson", errorJson);
			if (errorJson.hasOwnProperty("errors") && isObject(errorJson.errors)) {
				formatted = flatten(toArray(errorJson.errors));
			} else if (isObject(errorJson)) {
				formatted = flatten(toArray(errorJson));
			} else if (errorJson.hasOwnProperty("message")) {
				formatted = [errorJson.message];
			} else {
				formatted = [errorJson];
			}
		} else if (error.hasOwnProperty("responseText")) {
			formatted = [error.responseText];
		} else if (error.hasOwnProperty("message")) {
			formatted = [error.message];
		} else {
			formatted = [messageDefault];
		}

		return formatted;
	},

	sleep(milliseconds) {
		const date = Date.now();
		let currentDate = null;
		do {
			currentDate = Date.now();
		} while (currentDate - date < milliseconds);
	},

	pipelineValue(value) {
		let values = [];

		if (value.includes("|")) {
			values = value.split("|");
		} else if (value.includes("&")) {
			values = value.split("&");
		} else if (value.includes(",")) {
			values = value.split(",");
		} else if (isArray(value)) {
			values = value;
		} else {
			values.push(value);
		}

		return values;
	},

	hasRole(role, rolesForCheck = []) {
		let roles = HelperJS.pipelineValue(role);

		if (roles.length > 0) {
			return roles.some(_role => rolesForCheck.includes(_role));
		}

		return false;
	},

	hasProperty(obj, prop) {
		if (isUndefinedOrNull(obj)) {
			return false;
		}

		return Object.prototype.hasOwnProperty.call(obj, prop) && !isUndefinedOrNull(obj[prop]);
	},
	randomID() {
		return (Date.now().toString(36) + Math.random().toString(36).substr(2, 5)).toUpperCase();
	},
};

window.formatterErrors = object => {
	let errors = [];

	if (typeof object === "object") {
		Object.keys(object).forEach(key => {
			errors.push(object[key][0]);
		});
	} else {
		return object;
	}

	return errors;
};

window.deleteEntryDatatable = (buttonVar, nameUser = false, message = "Tem certeza que deseja apagar este item?") => {
	let button = $(buttonVar);
	let route = button.attr("data-route");
	let row = $("a[data-route='" + route + "']").closest("tr");

	const swallWithBootstrapButtons = Swal.mixin({
		buttonsStyling: true,
	});

	let htmlReturn = "";

	if (nameUser) {
		console.log(nameUser);

		htmlReturn = `<b>${nameUser}!</b><br/>`;
	}

	htmlReturn = htmlReturn + message;

	swallWithBootstrapButtons
		.fire({
			html: htmlReturn,
			type: "warning",
			showCancelButton: true,
			confirmButtonText: "Apagar",
			cancelButtonText: "Cancelar",
			reverseButtons: true,
		})
		.then(result => {
			if (result.value) {
				axios
					.delete(route)
					.then(response => {
						toastSuccess("Apagado com sucesso!");

						// Remove the row from the datatable
						row.remove();
					})
					.catch(error => {
						toastError();
						console.log(error);
					});
			} else if (result.dismiss === Swal.DismissReason.cancel) {
				toastInfo();
			}
		})
		.catch(error => {
			toastError();
		});
};

//REPORTS FORM VALIDATION
window.addEventListener("DOMContentLoaded", function () {
	var btn = document.getElementsByClassName("reportFormSubmitButton")[0];
	if (btn) {
		btn.addEventListener("click", function () {
			var reportForm = document.getElementsByClassName("needs-validation")[0];
			reportForm.classList.add("was-validated");
		});
	}
});

// window.onload = function() {
//     var sideBarMenuButton = document.getElementById('sideBarMenuButton');
//     sideBarMenuButton.addEventListener('click', function (event) {
//         event.preventDefault();
//
//         //default theme sidebar-enable
//         document.body.classList.toggle("sidebar-enable");
//     });
// };
