Vue.prototype.$http = axios;
Vue.config.performance = true;
Vue.config.runtimeCompiler = true;
Vue.prototype.__ = (key, replacements, locale) => lang.get(key, replacements, locale).replace("messages.", "");
Vue.prototype.$trans = (key, replacements, locale) => lang.get(key, replacements, locale).replace("messages.", "");
Vue.prototype.$choice = (key, number, replacements, locale) =>
	lang.choice(key, number, replacements, locale).replace("messages.", "");
Vue.prototype.$lang = lang;

Vue.prototype.$momentJS = momentJS;
Vue.filter("firstAndLastName", function (fullName) {
	var names = fullName.split(" ");
	var firstName = "";
	var lastName = "";
	if (!names || names.length <= 1) {
		firstName = names;
		lastName = "";
	} else {
		firstName = names[0];
		lastName = names.pop();
	}

	return firstName + " " + lastName;
});

Vue.filter("capitalize_words", function (str) {
	if (!str) return "";
	return str.replace(/\w\S*/g, function (txt) {
		return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
	});
});

Vue.filter("formatFullDate", function (value) {
	if (value) {
		return momentJS(value).format("DD/MM/YYYY H:mm:ss");
	}
	return "-";
});

Vue.filter("formatDate", function (value) {
	if (value) {
		return momentJS(String(value)).format("DD/MM/YYYY");
	}
});

Vue.filter("formatHour", function (value) {
	if (value) {
		return momentJS(String(value)).format("H:mm:ss");
	}
});

Vue.filter("toCurrency", function (value) {
	return HelperJS.toCurrency(value, {
		currency: "eur",
		locale: "pt-PT",
	});
});

Vue.filter("monthViewEvent", function (value) {
	if (value) {
		return HelperJS.monthViewEvent(value);
	}

	return value;
});

import sortTable from "./mixins/sortTable";
import VueTippy, { TippyComponent } from "vue-tippy";

Vue.mixin(sortTable);
Vue.mixin({
	data() {
		return {
			processSubmitted: false,
		};
	},
	methods: {
		validateEmail(email) {
			// var regex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
			// return regex.test(String(email).toLowerCase());
			return HelperJS.validateEmail(email);
		},

		isEmailValid(email, classSuccess = "", classError = "parsley-error") {
			return email === "" ? "" : this.validateEmail(email) ? classSuccess : classError;
		},

		existsNifOrEmail(value) {
			axios
				.get("/admin/user/verify-user-by-nif-or-email", {
					params: {
						q: value,
					},
				})
				.then(response => {
					return response;
				})
				.catch(error => {
					console.log(error);
				});
		},

		previewToken(id) {
			console.log(id);
			var field = document.getElementById(id);

			if (field.type === "password") {
				field.type = "text";
			} else {
				field.type = "password";
			}
		},

		openModalJQuery(modalIdentifier) {
			//console.log(modalIdentifier);
			$(modalIdentifier).modal("toggle");
		},

		toggleModalJQuery(modalIdentifier) {
			//console.log(modalIdentifier);
			$(modalIdentifier).modal("toggle");
		},

		/**
		 * @param method {string} | put or post
		 * @param uri {string}
		 * @param form {object}
		 * @param redirect {string|boolean}
		 * @param messageSuccess {string|null}
		 * @param messageError {string|null}
		 * @return {Promise<unknown>} Redirect or persist your function
		 */
		interceptorRequest(
			method,
			uri,
			form,
			redirect = "api",
			messageSuccess = "Gravado com sucesso!",
			messageError = "Não conseguimos atender a solicitação! Tente novamente mais tarde."
		) {
			//console.debug('persist submit global');
			//console.table(arguments);

			const _this = this;
			this.errors = [];
			this.processSubmitted = true;

			return new Promise((resolve, reject) => {
				Pace.track(() => {
					$.ajax({
						type: method,
						url: uri,
						data: form,
						success: response => {
							if (messageSuccess) {
								toastSuccess(messageSuccess);
							}

							setTimeout(() => {
								if (redirect === "api" && response.hasOwnProperty("redirect")) {
									window.location = response.redirect;
								} else if (redirect === "history") {
									if (document.referrer !== "") {
										//TODO: Por enquanto, ao fazer o redirect back, ela utiliza o cache da sessão, com isso, não recarrega as informações que vem do php.
										//Em pesquisa com uma solução para isso.
										history.go(-1);
									} else if (response.hasOwnProperty("redirect")) {
										window.location = response.redirect;
									}
								} else {
									console.warn("Without redirect");
								}
							}, 1000);

							resolve(response);
						},
						error: error => {
							_this.processSubmitted = false;

							this.errors = HelperJS.formatterErrorsLaravel(error);

							if (this.errors.length === 0) {
								this.errors = [messageError];
							}

							// if (error.responseJSON && typeof error.responseJSON.errors === 'object') {
							// 	this.errors = formatterErrors(error.responseJSON.errors);
							// } else if (error.responseJSON && error.responseJSON.message) {
							// 	this.errors = [error.responseJSON.message];
							// } else {
							// 	this.errors = [error.responseJSON];
							// }

							if (typeof window.Sentry !== "undefined") {
								window.Sentry.setExtra("body form", form);
								window.Sentry.setExtra("message", JSON.stringify(error.responseJSON));
								window.Sentry.setExtra("error in ajax", JSON.stringify(error));
							}

							console.error("errors to user", JSON.stringify(this.errors));
							$("html,body").animate({ scrollTop: 0 }, 400);
							reject(error);
						},
						complete: () => {
							setTimeout(() => {
								_this.processSubmitted = false;
							}, 6000);
						},
					});
				});
			});
		},

		vueRoute(name, params, absolute) {
			return this.route(name, params, absolute);
		},

		route(name, params, absolute) {
			return window.route(name, params, absolute);
		},

		changeTaskStatus(task) {
			if (task.done === true) {
				task.done = false;
				var taskDone = 0;
			} else {
				task.done = true;
				var taskDone = 1;
			}

			console.log(task, taskDone);

			var body = {
				taskId: task.id,
				taskDone: taskDone,
			};

			console.log(body);

			$.ajax({
				url: route("api.task.changeStatus"),
				data: body,
				method: "POST",
				success: function (data) {},
			});
		},

		checkDigit(event) {
			if (event.key.length === 1 && isNaN(Number(event.key))) {
				event.preventDefault();
			}
		},

		initializeVariable() {
			const el = document.getElementById("data-page");
			if (el) {
				let value = el.value;

				if (value) {
					Object.assign(this.$data, JSON.parse(value));
					setTimeout(() => {
						el.remove();
					}, 200);
				}
			} else if('dataPage' in window) {
				Object.assign(this.$data, window.dataPage);
			}
		},
		initializeVariables() {
			this.initializeVariable();
		},
	},
});

Vue.use(VueTippy);
Vue.component("Tippy", TippyComponent);

require("./register-components-vue.js");
